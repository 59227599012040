import TagManager from 'react-gtm-module';

const setUserType = (asPath: string) => {
  const type: 'SUPPORTED' | 'UNSUPPORTED' = asPath.includes('?hs_token')
    ? 'SUPPORTED'
    : 'UNSUPPORTED';

  // TODO: `/`が削除されたら(`/support`, `/self`のみになったら以下に条件を変える)
  // const type: 'SUPPORTED' | 'UNSUPPORTED' | null = (() => {
  //   if (asPath.includes('/support')) return 'SUPPORTED';
  //   if (asPath.includes('/self')) return 'UNSUPPORTED';
  //   return null;
  // })();

  // if (type === null) return;

  TagManager.dataLayer({
    dataLayer: {
      userType: type,
    },
  });
};

export const fireGAEvent = {
  initGA: () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'INIT_GA',
      },
    });
  },
  customEvents: {
    viewTACAndInputEmailSection: (asPath: string) => {
      setUserType(asPath);
      TagManager.dataLayer({
        dataLayer: {
          event: 'VIEW_TAC_AND_INPUT_EMAIL_SECTION',
        },
      });
    },
    viewConfirmEmailSection: (asPath: string) => {
      setUserType(asPath);
      TagManager.dataLayer({
        dataLayer: {
          event: 'VIEW_CONFIRM_EMAIL_SECTION',
        },
      });
    },
    viewInputAccountSection: (params: {
      tokenId: number;
      token: string;
      abTestPattern?: string;
    }) => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'VIEW_INPUT_ACCOUNT_SECTION',
          ...params,
        },
      });
    },
    viewInputCompanyWithSearchSection: (params: {
      tokenId: number;
      token: string;
      businessForm: 'corporate' | 'selfEmployed';
      abTestPattern?: string;
    }) => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'VIEW_INPUT_COMPANY_WITH_SEARCH_SECTION',
          ...params,
        },
      });
    },
    changeBusinessForm: (params: {
      tokenId: number;
      token: string;
      businessForm: 'corporate' | 'selfEmployed';
      abTestPattern?: string;
    }) => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'CHANGE_BUSINESS_FORM',
          ...params,
        },
      });
    },
    occurValidationErrorInBusinessRegistration: (params: { tokenId: number; token: string }) => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'OCCUR_VALIDATION_ERROR_IN_BUSINESS_REGISTRATION',
          ...params,
        },
      });
    },
    openCorporateSearchFunction: (params: {
      tokenId: number;
      token: string;
      abTestPattern?: string;
    }) => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'OPEN_CORPORATE_SEARCH_FUNCTION',
          ...params,
        },
      });
    },
    selectManualInputOfCorporateInformation: (params: {
      tokenId: number;
      token: string;
      abTestPattern?: string;
    }) => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'SELECT_MANUAL_INPUT_OF_CORPORATE_INFORMATION',
          ...params,
        },
      });
    },
    selectCorporation: (params: { tokenId: number; token: string; abTestPattern?: string }) => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'SELECT_CORPORATION',
          ...params,
        },
      });
    },
    clickWorkerAppLink: () => {
      TagManager.dataLayer({
        dataLayer: {
          event: `CLICK_WORKER_APP_LINK`,
        },
      });
    },
    completeClientRegistration: () => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'COMPLETE_CLIENT_REGISTRATION',
        },
      });
    },
    completeCompanyAndAccountRegistration: () => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'COMPLETE_COMPANY_AND_ACCOUNT_REGISTRATION',
        },
      });
    },
  },
};
